import { useRef } from 'react';
import QRCode from 'react-qr-code';
import Button, { BUTTON_TYPES } from '../../components/Button';
import ModalBase from '../../components/ModalBase';
import ModalActionBar from '../../components/ModalActionBar';
import ModalContent from '../../components/ModalContent';
import { useReactToPrint } from 'react-to-print';

const QRModal = ({ name, url, onClose }) => {
  const printRef = useRef(null);
  const onPrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <ModalBase>
      <ModalContent>
        <div className="flex-col w-full">
          <div className="text-lg">{name}</div>
          <div className="text-gray-400 italic text-sm break-all">{url}</div>

          <div className="flex w-full justify-center mt-2">
            <QRCode ref={printRef} value={url} size={128} />
          </div>
        </div>
      </ModalContent>
      <ModalActionBar>
        <div className="flex flex-row gap-x-2">
          <Button type={BUTTON_TYPES.SECONDARY} ariaLabel="Close" isDisabled={false} onClick={onClose}>
            Close
          </Button>
          <Button type={BUTTON_TYPES.PRIMARY} ariaLabel="Print" isDisabled={false} onClick={onPrint}>
            Print
          </Button>
        </div>
      </ModalActionBar>
    </ModalBase>
  );
};

export default QRModal;

import {
  AutomationStatus,
  DraftAction,
  IRedlineId,
  ProcedureType,
  Reviewer,
  ReviewerAction,
  ReviewerGroup,
  DraftState as SharedDraftState,
  Participant as SharedParticipant,
  ProcedureMetadata as SharedProcedureMetadata,
  ProcedureState as SharedProcedureState,
  RedlinesMetadata as SharedRedlinesMetadata,
  ReleaseState as SharedReleaseState,
  RunMetadata as SharedRunMetadata,
  RunState as SharedRunState,
  RunStatus as SharedRunStatus,
  StepState as SharedStepState,
  StepDuration,
  StepTiming,
} from '../couch/procedures';
import { RunTag, Tag } from '../couch/settings';
import { OperationId } from '../operations';
import { Severity, Status } from '../postgres/issues';
import {
  ComponentPart,
  Part,
  TrackingType,
} from '../postgres/manufacturing/types';
import { Mention } from '../postgres/util';
import { TelemetryType } from '../postgres/telemetry';
import { ReviewType } from '../settings';
import { Case } from '../testing';
import { Overwrite } from '../utilTypes';
import {
  RequirementIntegrationDetails,
  RequirementIntegrationDetailsDiff,
} from '../api/integrations/integrations';
import { SupportedOperation } from '../../math';

/*
 * =============================================================================
 * Metadata types
 * =============================================================================
 */
export type ProcedureMetadata = SharedProcedureMetadata;

export type RunMetadata = SharedRunMetadata;

export type RedlinesMetadata = SharedRedlinesMetadata;

/*
 * =============================================================================
 * Misc Types
 * =============================================================================
 */
export type Approval = {
  user_id: string;
  approved_at: string;
};

export type PrintContent = {
  text?: string;
  variable_id?: string;
};

export type PrintSettings = {
  header?: PrintContent;
  footer?: PrintContent;
};

export type Settings = {
  print_settings?: PrintSettings;
};

export type SettingsDiff = WithDiffChange<{
  print_settings?: PrintSettingsDiff;
}>;

export type PrintSettingsDiff = {
  header?: PrintContentDiff;
  header__added?: PrintContent;
  header__deleted?: PrintContent;

  footer?: PrintContentDiff;
  footer__added?: PrintContent;
  footer__deleted?: PrintContent;
};

export type PrintContentDiff = {
  text?: DiffField<string>;
  text__added?: string;
  text__deleted?: string;

  variable_id?: DiffField<string>;
  variable_id__added?: string;
  variable_id__deleted?: string;
};

/*
 * =============================================================================
 * Procedure types
 * =============================================================================
 */
export type ProcedureSettings = {
  automation_enabled?: boolean;
  is_strict_signoff_enabled?: boolean;
} & Omit<StepSettings, 'runAsBatch'>;

export type Draft = {
  _id: string;
  _rev?: string;
  procedure_id?: string;
  project_id?: string;
  code: string;
  name: string;
  variables?: Array<DraftVariable>;
  risks?: Array<ProcedureRisk>;
  part_list?: PartList;
  headers?: Array<DraftHeader>;
  version?: string;
  description: string;
  archived?: boolean;
  state?: DraftState;
  sections: Array<DraftSection>;
  review_type?: Pick<ReviewType, 'id' | 'name'>;
  reviewer_groups?: Array<ReviewerGroup>;
  reviewer_actions_history?: Array<ReviewerAction>;
  locked_by?: string;
  locked_at?: string;
  redline_actions?: Array<RedlineAction>;
  editedAt?: string;
  editedUserId?: string;
  owner?: string;
  procedure_rev_num?: number;
  release_note?: ReleaseNote;
  comments?: Array<DraftRedlineComment | ReviewComment>;
  approvals?: Array<Approval>;
  tags?: Array<Tag>;
  settings?: Settings;
  reviewers?: Array<Reviewer>;
  end_run_signoffs_groups?: Array<EndRunSignoff>;
  start_run_signoffs_groups?: Array<StartRunSignoff>;
  default_view_mode?: string;
  dictionary_id?: number;
  procedure_type?: ProcedureType;
  actions?: Array<DraftAction | ReviewerAction>;
  initial_rev_num?: number;
  auto_procedure_id_enabled?: boolean;
} & ProcedureSettings;

export type DraftState = SharedDraftState;

export type RedlineAction = {
  redline_id: string;
  state: 'accepted' | 'rejected' | 'resolved'; // Currently an action is only taken to resolve a redline in some way (as opposed to unresolving it)
  resolved_at: string;
  resolved_by: string;
  resolution_procedure_rev_num?: number;
  release_procedure_rev_num?: number;
};

export type Release = {
  _id: string;
  _rev?: string;
  procedure_id?: string;
  project_id?: string;
  procedure_rev_num?: number;
  code: string;
  name: string;
  variables?: Array<ReleaseVariable>;
  risks?: Array<ProcedureRisk>;
  part_list?: PartList;
  headers?: Array<ReleaseHeader>;
  version?: string;
  description: string;
  archived?: boolean;
  state?: ReleaseState;
  sections: Array<ReleaseSection>;
  review_type?: Pick<ReviewType, 'id' | 'name'>;
  reviewer_groups?: Array<ReviewerGroup>;
  reviewer_actions_history?: Array<ReviewerAction>;
  editedAt?: string;
  editedUserId?: string;
  owner?: string;
  release_note?: string;
  approvals?: Array<Approval>;
  tags?: Array<Tag>;
  settings?: Settings;
  reviewers?: Array<Reviewer>;
  default_view_mode?: string;
  end_run_signoffs_groups?: Array<EndRunSignoff>;
  dictionary_id?: string;
  procedure_type?: ProcedureType;
  auto_procedure_id_enabled?: boolean;
} & ProcedureSettings;

export type ReleaseState = SharedReleaseState;

export type ProcedurePrimitive = string | number | boolean | undefined | null;

export type DiffField<T extends ProcedurePrimitive> = T | DiffFieldChange<T>;

export type DiffFieldChange<T extends ProcedurePrimitive> = {
  __old: T;
  __new: T;
};

export type DiffArrayChangeSymbol = '+' | '-' | '~' | ' ';
type DiffArrayElement<T> = Array<DiffArrayChangeSymbol | T>;
export type ProcedureDiff = {
  _id: DiffField<string>;
  code: DiffField<string>;
  name: DiffField<string>;
  version?: DiffField<string>;
  description: DiffField<string>;
  state?: DiffField<ReleaseState | DraftState>;
  variables?: Array<VariableDiff> | Array<VariableDiffElement>;
  variables__previous?: Array<ReleaseVariable>;
  headers?: Array<HeaderDiff> | Array<HeaderDiffElement>;
  headers__added?: Array<Header>;
  headers__deleted?: Array<Header>;
  part_list?: PartList;
  sections: Array<SectionDiff> | Array<SectionDiffElement>;
  editedAt?: string;
  editedUserId?: string;
  reviewer_groups?: Array<ReviewerGroup>;
  reviewer_actions_history?: Array<ReviewerAction>;
  release_note?: ReleaseNote;
  release_note__added?: ReleaseNote;
  release_note__deleted?: ReleaseNote;
  comments?: Array<ReviewComment>;
  end_run_signoffs_groups?:
    | Array<EndRunSignoffsGroupsDiff>
    | Array<EndRunSignoffsGroupsDiffElement>;
  end_run_signoffs_groups__added?: Array<EndRunSignoff>;
  end_run_signoffs_groups__deleted?: Array<EndRunSignoff>;
  tags?: Array<TagDiff> | Array<TagDiffElement>;
  tags__added?: Array<Tag>;
  tags__deleted?: Array<Tag>;
  settings?: SettingsDiff;
  settings__added?: Settings;
  settings__deleted?: Settings;
  owner?: DiffField<string>;
  owner__added?: string;
  owner__deleted?: string;
  project_id?: DiffField<string>;
  project_id__added?: string;
  project_id__deleted?: string;
  default_view_mode?: DiffField<string>;
  default_view_mode__added?: string;
  default_view_mode__deleted?: string;

  // TODO: Add properties as needed.
};

export type Diffable =
  | EndRunSignoff
  | Tag
  | Variable
  | Header
  | Section
  | SectionHeader
  | Step
  | StepBlock
  | StepDependency
  | Conditional
  | Signoff
  | JumpToBlock
  | FieldInputBlock
  | Rule
  | Range
  | ExpressionToken;
export type DiffableDiff =
  | EndRunSignoffsGroupsDiff
  | TagDiff
  | VariableDiff
  | HeaderDiff
  | SectionDiff
  | SectionHeaderDiff
  | StepDiff
  | StepBlockDiff
  | HeaderBlockDiff
  | StepHeaderDiff
  | StepDependencyDiff
  | ConditionalDiff
  | SignoffDiff
  | ExpressionTokenDiff;
export type DiffElement =
  | EndRunSignoffsGroupsDiffElement
  | TagDiffElement
  | VariableDiffElement
  | HeaderDiffElement
  | SectionDiffElement
  | StepDiffElement
  | StepBlockDiffElement
  | HeaderBlockDiffElement
  | StepHeaderDiffElement
  | StepDependencyDiffElement
  | ConditionalDiffElement
  | SignoffDiffElement
  | JumpToBlockDiffElement
  | FieldInputBlockDiffElement
  | RuleDiffElement
  | RangeDiffElement;

export interface WithDiffChangeI {
  diff_change_state?: DiffArrayChangeSymbol;
}
export type WithDiffChange<T> = T & {
  diff_change_state?: DiffArrayChangeSymbol;
};
export type EndRunSignoffsGroupsDiff =
  DiffArrayElement<EndRunSignoffsGroupsDiffElement>;
export type EndRunSignoffsGroupsDiffElement = WithDiffChange<{
  id: string;
  operators: Array<DiffArrayElement<string>>;
}>;

export type TagDiff = DiffArrayElement<TagDiffElement>;
export type TagDiffElement = WithDiffChange<Tag>;

export type VariableDiff = DiffArrayElement<Variable>;
export type VariableDiffElement = WithDiffChange<Variable>;

export type HeaderDiff = DiffArrayElement<HeaderDiffElement>;
export type HeaderDiffElement = WithDiffChange<
  | Header
  | {
      id: DiffField<string>;
      name: DiffField<string>;
      content: Array<HeaderBlockDiff> | Array<HeaderBlockDiffElement>;
    }
>;

export type SectionDiff = DiffArrayElement<SectionDiffElement>;

export type SectionDiffElement = WithDiffChange<
  | Section
  | {
      id: DiffField<string>;
      name: DiffField<string>;
      headers?: Array<SectionHeaderDiff> | Array<SectionHeaderDiffElement>;
      steps: Array<StepDiff> | Array<StepDiffElement>;
      // TODO: Add properties as needed.
    }
>;

export type HeaderBlockDiff = DiffArrayElement<HeaderBlockDiffElement>;
export type HeaderBlockDiffElement =
  | AlertBlockDiffElement
  | AttachmentBlockDiffElement
  | TextBlockDiffElement
  | TableInputBlockDiffElement;

export type SectionHeaderDiff = DiffArrayElement<SectionHeaderDiffElement>;
export type SectionHeaderDiffElement = WithDiffChange<
  | SectionHeader
  | {
      id: DiffField<string>;
      name: DiffField<string>;
      content: Array<HeaderBlockDiff> | Array<HeaderBlockDiffElement>;
      redlines?: Array<DraftHeaderRedlinedBlock | RunHeaderRedline>;
    }
>;

export type StepHeaderBlockDiff = DiffArrayElement<StepHeaderBlockDiffElement>;
export type StepHeaderBlockDiffElement =
  | WithDiffChange<StepHeaderBlock>
  | AlertBlockDiffElement
  | TextBlockDiffElement;

export type StepHeaderDiff = DiffArrayElement<StepHeaderDiffElement>;
export type StepHeaderDiffElement = WithDiffChange<
  | StepHeader
  | {
      id: DiffField<string>;
      name: DiffField<string>;
      content: Array<StepHeaderBlockDiff> | Array<StepHeaderBlockDiffElement>;
    }
>;

export type StepDiff = DiffArrayElement<StepDiffElement>;

export type StepDiffElement = WithDiffChange<
  | Step
  | {
      id: DiffField<string>;
      name: DiffField<string>;
      content: Array<StepBlockDiff> | Array<StepBlockDiffElement>;
      dependencies:
        | Array<StepDependencyDiff>
        | Array<StepDependencyDiffElement>;
      conditionals?: Array<ConditionalDiff> | Array<ConditionalDiffElement>;
      signoffs: Array<SignoffDiff> | Array<SignoffDiffElement>;
      headers?: Array<StepHeaderDiff> | Array<StepHeaderDiffElement>;
      // Will add properties here as needed.
    }
>;

export type SignoffDiff = DiffArrayElement<SignoffDiffElement>;
export type SignoffDiffElement = WithDiffChange<{
  id: string;
  operators: Array<DiffField<string>>;
}>;

export type StepBlockDiff = DiffArrayElement<StepBlockDiffElement>;
export type StepBlockDiffElement =
  | WithDiffChange<StepBlock>
  | AlertBlockDiffElement
  | AttachmentBlockDiffElement
  | TextBlockDiffElement
  | RequirementBlockDiffElement
  | FieldInputBlockDiffElement
  | InputReferenceBlockDiff
  | JumpToBlockDiffElement
  | ExpressionBlockDiffElement
  | TableInputBlockDiffElement
  | ExternalItemBlockDiffElement
  | TelemetryBlockDiffElement;

export type StepDependencyDiff = DiffArrayElement<StepDependencyDiffElement>;
export type StepDependencyDiffElement = WithDiffChange<
  StepDependency | { id: string; dependent_ids: Array<DiffField<string>> }
>;

export type ConditionalDiff = DiffArrayElement<ConditionalDiffElement>;
export type ConditionalDiffElement =
  | StepConditionalDiffElement
  | FieldInputConditionalDiffElement
  | ContentBinaryConditionalDiffElement
  | ContentTernaryConditionalDiffElement;

export type FieldInputConditionalDiffElement = WithDiffChange<
  | FieldInputConditional
  | {
      id: string;
      source_type: 'content';
      state: DiffField<string>;
      content_id: DiffField<string>;
      source_id: DiffField<string>;
      target_id: DiffField<string>;
      target_type: 'step';
    }
>;

export type ContentBinaryConditionalDiffElement = WithDiffChange<
  | ContentBinaryConditional
  | {
      id: string;
      source_type: 'content_binary';
      state: DiffField<ContentBinaryConditionalState>;
      content_id: DiffField<string>;
      source_id: DiffField<string>;
      target_id: DiffField<string>;
      target_type: 'step';
    }
>;

export type ContentTernaryConditionalDiffElement = WithDiffChange<
  | ContentTernaryConditional
  | {
      id: string;
      source_type: 'content_ternary';
      state: DiffField<ContentTernaryConditionalState>;
      content_id: DiffField<string>;
      source_id: DiffField<string>;
      target_id: DiffField<string>;
      target_type: 'step';
    }
>;

export type StepConditionalDiffElement = WithDiffChange<
  | StepConditional
  | {
      id: string;
      source_type: 'step';
      state: DiffField<StepConditionalState>;
      content_id: null;
      source_id: DiffField<string>;
      target_id: DiffField<string>;
      target_type: 'step';
    }
>;

export type AlertBlockDiffElement = WithDiffChange<
  | AlertBlock
  | {
      id: string;
      type: 'alert';
      subtype: DiffField<AlertSubtype>;
      text: DiffField<string>;
    }
>;

export type AttachmentBlockDiffElement = WithDiffChange<
  | AttachmentBlock
  | {
      id: string;
      type: 'attachment';
      attachment_id: DiffField<string>;
      name?: DiffField<string>;
      file?: File;
      caption?: DiffField<string>;
      size?: DiffField<'small' | 'best_fit' | 'original'>;
    }
>;

export type TextBlockDiffElement = WithDiffChange<
  | TextBlock
  | {
      id: string;
      type: 'text';
      text: DiffField<string>;
    }
>;

export type RequirementBlockDiffElement = WithDiffChange<
  | RequirementBlock
  | {
      id: string;
      type: 'requirement';
      requirement_id: DiffField<string>;
      label: DiffField<string>;
      metadata: object;
      subType: DiffField<RequirementSubType>;
      integrationDetails?: RequirementIntegrationDetailsDiff;
    }
>;

export type JumpToBlockDiffElement = WithDiffChange<
  JumpToBlock | { id: string; type: 'jump_to'; jumpToId: DiffField<string> }
>;

export enum ParentReferenceType {
  Run = 'run',
  Issue = 'issue',
  Risk = 'risk',
  Event = 'event',
}

export type RunParentReference = {
  id: string | number;
  type: ParentReferenceType;
};

export type Run = {
  _id: string;
  _rev?: string;
  name: string;
  procedure_id?: string;
  run_number?: number;
  project_id?: string;
  headers?: Array<RunHeader>;
  code: string;
  variables?: Array<RunVariable>;
  risks?: Array<ProcedureRisk>;
  part_list?: PartList;
  version?: string;
  description: string;
  owner?: string;
  editedAt?: string;
  editedUserId?: string;
  starttime: string;
  state?: RunState;
  status?: RunStatus;
  procedureRev?: string;
  sections: Array<RunSection | RepeatedSection>;
  participants?: Array<Participant>;
  archived?: boolean;
  actions?: Array<RunAction>;
  reviewer_actions_history?: Array<ReviewerAction>;
  reviewer_groups?: Array<ReviewerGroup>;
  operation?: OperationId;
  run_tags?: Array<RunTag>;
  run_section?: string;
  completedAt?: string;
  completedUserId?: string;
  procedure_rev_num?: number;
  approvals?: Array<Approval>;
  tags?: Array<Tag>;
  settings?: Settings;
  reviewers?: Array<Reviewer>;
  default_view_mode?: string;
  source_run?: string;
  parent_reference?: RunParentReference;
  started_by?: StartedBy;
  issues?: Record<string, RunIssue>; // Used only for JIRA issues
  /** @todo - should be `procedure_id` */
  procedure?: string;
  end_run_signoffs_groups?: Array<EndRunSignoff>;
  start_run_signoffs_groups?: Array<StartRunSignoff>;
  dictionary_id?: string;
  comments?: Array<ReviewComment>;
  operators?: Array<string>;
  procedure_type?: ProcedureType;
  automation_status?: AutomationStatus;
  test_case_list?: TestCaseList;
  auto_procedure_id_enabled?: boolean;
} & ProcedureSettings;

export type RunState = SharedRunState;

export type StepState = SharedStepState;

export type RunStatus = SharedRunStatus;

export type PauseAction = {
  timestamp: string;
  user_id: string;
  type: 'pause';
  comment: string;
};

export type ResumeAction = {
  timestamp: string;
  user_id: string;
  type: 'resume';
};

export type ChangeOperationAction = {
  timestamp: string;
  user_id: string;
  type: 'change operation';
  comment: string;
};

export type ReopenAction = {
  timestamp: string;
  user_id: string;
  type: 'reopen';
  comment: string;
};

export type RunAction =
  | PauseAction
  | ResumeAction
  | ChangeOperationAction
  | ReopenAction;

export type Participant = SharedParticipant;

type StartedBy = {
  method: 'web' | 'api';
  user_id: string;
};

export type ProcedureState = SharedProcedureState;

export type Procedure = Draft | Release | Run;

/*
 * =============================================================================
 * Variable types
 * =============================================================================
 */

export type LegacyDraftVariable = {
  name: string;
  type: 'input';
  input_type?: 'text' | 'number' | 'checkbox';
};

export type LegacyReleaseVariable = {
  name: string;
  type: 'input';
  input_type?: 'text' | 'number' | 'checkbox';
};

export type LegacyRunVariable = {
  name: string;
  type: 'input';
  input_type?: 'text' | 'number' | 'checkbox';
  value?: string | number | boolean;
};

export type LegacyVariable =
  | LegacyDraftVariable
  | LegacyReleaseVariable
  | LegacyRunVariable;

export type V2DraftVariable = DraftFieldInputBlock & {
  version: 2;
  dateTimeType: 'date' | 'time' | 'datetime'; // for legacy reasons
};

export type V2ReleaseVariable = ReleaseFieldInputBlock & {
  version: 2;
};

export type V2RunVariableTextBlock = Omit<
  RunFieldInputTextBlock,
  'recorded'
> & {
  version: 2;
  value?: string;
};

export type V2RunVariableNumberBlock = Omit<
  RunFieldInputNumberBlock,
  'recorded'
> & {
  version: 2;
  value?: number;
};

export type V2RunVariableCheckboxBlock = Omit<
  RunFieldInputCheckboxBlock,
  'recorded'
> & {
  version: 2;
  value?: boolean;
};

export type V2RunVariableTimestampBlock = Omit<
  RunFieldInputTimestampBlock,
  'recorded'
> & {
  version: 2;
  value?: string;
};

export type V2RunVariableAttachmentBlock = Omit<
  RunFieldInputAttachmentBlock,
  'recorded'
> & {
  version: 2;
  value?: AttachmentValue;
};

export type V2RunVariableSketchBlock = Omit<
  RunFieldInputSketchBlock,
  'recorded'
> & {
  version: 2;
  value?: SketchValue;
};

export type V2RunVariableSettingsListBlock = Omit<
  RunFieldInputSettingsListBlock,
  'recorded'
> & {
  version: 2;
  value?: string;
};

export type V2RunVariableCustomListBlock = Omit<
  RunFieldInputCustomListBlock,
  'recorded'
> & {
  version: 2;
  value?: string;
};

export type V2RunVariableExternalDataBlock = Omit<
  RunFieldInputExternalDataBlock,
  'recorded'
> & {
  version: 2;
  value?: ExternalDataValue;
};

export type V2RunVariableExternalSearchBlock = Omit<
  RunFieldInputExternalSearchBlock,
  'recorded'
> & {
  version: 2;
  value?: ExternalDataValue;
};

export type V2RunVariableMultipleChoiceBlock = Omit<
  RunFieldInputMultipleChoiceBlock,
  'recorded'
> & {
  version: 2;
  value?: string;
};

export type V2RunVariable =
  | V2RunVariableTextBlock
  | V2RunVariableNumberBlock
  | V2RunVariableCheckboxBlock
  | V2RunVariableTimestampBlock
  | V2RunVariableAttachmentBlock
  | V2RunVariableSketchBlock
  | V2RunVariableSettingsListBlock
  | V2RunVariableCustomListBlock
  | V2RunVariableExternalDataBlock
  | V2RunVariableExternalSearchBlock
  | V2RunVariableMultipleChoiceBlock;

// For backwards compatibility to connected clients
export type RunVariableDataTransferObject = {
  name: string;
  input_type?: FieldInputType;
  value?: RunFieldInputRecordedValue;
};

export type V2Variable = V2DraftVariable | V2ReleaseVariable | V2RunVariable;

export type DraftVariable = LegacyDraftVariable | V2DraftVariable;
export type ReleaseVariable = LegacyReleaseVariable | V2ReleaseVariable;
export type RunVariable = LegacyRunVariable | V2RunVariable;

export type Variable = DraftVariable | ReleaseVariable | RunVariable;

/*
 * =============================================================================
 * Risk types
 * =============================================================================
 */

export type ProcedureRisk = {
  id: number;
  title: string;
};

/*
 * =============================================================================
 * Part types
 * =============================================================================
 */

export type PartList = {
  id: string;
  items: ComponentPart[];
  part: Part;
  part_id: string;
  type: 'part_list';
};

/*
 * =============================================================================
 * TCM types
 * =============================================================================
 */

type TestCaseList = {
  id: string;
  items: string[];
};

/*
 * =============================================================================
 * Header types
 * =============================================================================
 */
export type DraftHeader = {
  id: string;
  name: string;
  content: Array<DraftHeaderBlock>;
  header_field_redlines?: {
    [field: string]: Array<DraftHeaderFieldRedline>;
  };
};

export type ReleaseHeader = {
  id: string;
  name: string;
  content: Array<ReleaseHeaderBlock>;
};

export type RunHeader = {
  id: string;
  name: string;
  content: Array<RunHeaderBlock>;
  redlines?: Array<RunHeaderRedline>;
};

export type Header = DraftHeader | ReleaseHeader | RunHeader;

export type RedlinedHeader = ReleaseHeader;

export type HeaderRedlineMetadata = { contentId: string } | { field: 'name' };

/*
 * =============================================================================
 * Section types
 * =============================================================================
 */
export type DraftSection = {
  id: string;
  name: string;
  headers?: Array<DraftSectionHeader>;
  steps: Array<DraftStep | DraftAddedStep>;
  snippet_id?: string;
  snippet_name?: string;
  snippet_rev?: number;
  snippet_ids_map?: Record<string, string>;
  shows_snippet_detached_message?: boolean;
};

export type ReleaseSection = {
  id: string;
  name: string;
  headers?: Array<ReleaseSectionHeader>;
  steps: Array<ReleaseStep>;
  snippet_id?: string;
  snippet_name?: string;
  snippet_rev?: number;
  snippet_ids_map?: Record<string, string>;
};

export type RunSection = {
  id: string;
  name: string;
  headers?: Array<RunSectionHeader>;
  steps: Array<RunStep | RunAddedStep | RepeatedStep>;
  snippet_id?: string;
  snippet_name?: string;
  snippet_rev?: number;
  snippet_ids_map?: Record<string, string>;
};

export type RepeatedSection = RunSection & {
  repeat_of: string;
  repeated_at: string;
  repeated_user_id: string;
};

export type Section = DraftSection | ReleaseSection | RunSection;

/*
 * =============================================================================
 * Section header types
 * =============================================================================
 */
export type DraftSectionHeader = {
  id: string;
  name: string;
  content: Array<DraftSectionHeaderBlock>;
  redlines?: Array<DraftHeaderRedlinedBlock | RunHeaderRedline>;
};

export type ReleaseSectionHeader = {
  id: string;
  name: string;
  content: Array<ReleaseSectionHeaderBlock>;
  redlines?: Array<DraftHeaderRedlinedBlock | RunHeaderRedline>;
};

export type RunSectionHeader = {
  id: string;
  name: string;
  content: Array<RunSectionHeaderBlock>;
  redlines?: Array<RunHeaderRedline>;
};

export type SectionHeader =
  | DraftSectionHeader
  | ReleaseSectionHeader
  | RunSectionHeader;

/*
 * =============================================================================
 * Step types
 * =============================================================================
 */
export type Signoff = {
  id: string;
  operators: Array<string>;
};

export type EndRunSignoff = {
  id: string;
  operators: Array<string>;
};

export type StartRunSignoff = {
  id: string;
  operators: Array<string>;
};

export type StepDependency = {
  id: string;
  dependent_ids: Array<string>;
};

export type SourceConditionalsMap = {
  [stepId: string]: { [conditionalId: string]: StepConditionalDiffElement };
};

export type SourceType =
  | 'step'
  | 'content'
  | 'content_binary'
  | 'content_ternary';

export type StepConditional = {
  id: string;
  source_type: 'step';
  state: StepConditionalState;
  content_id: null;
  source_id: string;
  target_id: string;
  target_type: 'step';
};

export type StepConditionalState = 'completed' | 'failed';

export type FieldInputConditional = {
  id: string;
  source_type: 'content';
  state: string;
  content_id: string;
  source_id: string;
  target_id: string;
  target_type: 'step';
};

export type ContentBinaryConditionalState = 'pass' | 'fail';

export type ContentBinaryConditional = {
  id: string;
  source_type: 'content_binary';
  state: ContentBinaryConditionalState;
  content_id: string;
  source_id: string;
  target_id: string;
  target_type: 'step';
};

export type ContentTernaryConditionalState =
  | 'pass'
  | 'fail_high'
  | 'fail_low'
  | 'fail_no_data';

export type ContentTernaryConditional = {
  id: string;
  source_type: 'content_ternary';
  state: ContentTernaryConditionalState;
  content_id: string;
  source_id: string;
  target_id: string;
  target_type: 'step';
};

export type Conditional =
  | StepConditional
  | FieldInputConditional
  | ContentBinaryConditional
  | ContentTernaryConditional;

export type StepEndAction = {
  type: 'complete' | 'fail' | 'skip';
  user_id: string;
  timestamp: string;
  conditional_value?: ConditionalValue;
};

export type StepSignoffAction = {
  type: 'signoff';
  user_id: string;
  timestamp: string;
  conditional_value?: ConditionalValue;
  signoff_id: string;
  operator: string;
  device_user_id?: string;
};

export type StepRevokeSignoffAction = {
  type: 'revoke_signoff';
  user_id: string;
  timestamp: string;
  signoff_id: string;
  revoked_user_id: string;
  revoked_operator: string;
  revoked_conditional_value?: ConditionalValue;
};

export type StepIssueAction = {
  type: 'issue';
  user_id: string;
  timestamp: string;
  issue_id: string;
};

export type StepAutomationPauseAction = {
  type: 'automation_pause';
  user_id: string;
  timestamp: string;
};

export type ConditionalValue = string;

export type StepAction =
  | StepSignoffAction
  | StepRevokeSignoffAction
  | StepEndAction
  | StepIssueAction
  | StepAutomationPauseAction;

export type BatchStepProps = {
  batchSize: number;
  index: number;
  batchId: string;
  instanceId: string;
};

export type DraftStep = {
  id: string;
  name: string;
  type?: string;
  headers?: Array<DraftStepHeader>;
  timing?: string;
  timer?: StepTiming;
  duration?: StepDuration | string;
  location?: string;
  channel?: string;
  content: Array<DraftStepBlock>;
  signoffs: Array<Signoff>;
  dependencies?: Array<StepDependency>;
  conditionals?: Array<Conditional>;
  actions?: Array<StepAction>;
  step_field_redlines?: {
    [field: string]: Array<DraftStepFieldRedline>;
  };
  shows_snippet_detached_message?: boolean;
  snippet_id?: string;
  snippet_name?: string;
  snippet_rev?: number;
  snippet_ids_map?: Record<string, string>;
  redline_id?: string;
  precedingStepId?: string;
  automation_status?: AutomationStatus;
  /** @deprecated */
  requires_previous?: boolean;
  created_during_run?: boolean;
} & StepSettings;

export type ReleaseStep = {
  id: string;
  name: string;
  type?: string;
  headers?: Array<ReleaseStepHeader>;
  timing?: string;
  timer?: StepTiming;
  duration?: StepDuration | string;
  location?: string;
  channel?: string;
  content: Array<ReleaseStepBlock>;
  signoffs: Array<Signoff>;
  dependencies?: Array<StepDependency>;
  conditionals?: Array<Conditional>;
  actions?: Array<StepAction>;
  snippet_id?: string;
  snippet_name?: string;
  snippet_rev?: number;
  snippet_ids_map?: Record<string, string>;
  precedingStepId?: string;
  automation_status?: AutomationStatus;
  /** @deprecated */
  requires_previous?: boolean;
  batchProps?: BatchStepProps;
} & StepSettings;

export type StepSettings = {
  [setting in
    | 'skip_step_enabled'
    | 'repeat_step_enabled'
    | 'step_suggest_edits_enabled'
    | 'runAsBatch']?: boolean | undefined;
};

export type RunStep = {
  id: string;
  run_id?: string;
  name: string;
  type?: string;
  /*
   * The state property, if it exists, on any type of run step can only be failed. Completed steps have a completed property, and skipped steps have a skipped property.
   * The state property is meant to be a move towards consolidating these various properties into one property.
   */
  state?: 'failed' | 'paused';
  headers?: Array<RunStepHeader>;
  content: Array<RunStepBlock>;
  dependencies?: Array<StepDependency>;
  signoffs: Array<Signoff>;
  conditionals?: Array<Conditional>;
  actions?: Array<StepAction>;
  completed?: true;
  completedAt?: string;
  completedUserId?: string;
  skipped?: true;
  skippedAt?: string;
  skippedUserId?: string;
  snippet_id?: string;
  snippet_name?: string;
  snippet_rev?: number;
  snippet_ids_map?: Record<string, string>;
  timing?: string;
  timer?: StepTiming;
  duration?: StepDuration | string;
  location?: string;
  channel?: string;
  redline_comments?: Array<RunRedlineComment>;
  redlines?: Array<RunStepRedline>;
  comments?: Array<RunStepComment>;
  shows_snippet_detached_message?: boolean;
  precedingStepId?: string;
  automation_status?: AutomationStatus;
  /** @deprecated */
  requires_previous?: boolean;
  batchProps?: BatchStepProps;
} & StepSettings;

export type RepeatedStep = RunStep & {
  repeat_of: string;
  repeated_at: string;
  repeated_user_id: string;
};

export type RunStepComment = {
  text: string;
  timestamp: string;
  updated_at?: string;
  user: string;
  id: string;
  parent_id: string;
  attachment?: object;
  mention_list?: Array<Mention>;
  reference_id?: string;
};

export enum IssueType {
  Jira,
  Internal,
}

export enum SignoffType {
  Start = 'start',
  End = 'end',
}

export type RunIssue = {
  id: string | number; // JIRA uses strings, NCR uses number
  text: string;
  url?: string;
  type: IssueType;
  severity?: Severity;
  status?: Status;
};

export type Step = DraftStep | ReleaseStep | RunStep;

export type RedlinedStep = Omit<ReleaseStep, 'id'>;

export type DraftAddedStep = {
  id: string;
  name: string;
  snippet_id?: string;
  snippet_rev?: number;
  snippet_ids_map?: Record<string, string>;
  timing?: string;
  timer?: StepTiming;
  duration?: StepDuration | string;
  location?: string;
  channel?: string;
  content: Array<ReleaseStepBlock>;
  signoffs: Array<Signoff>;
  dependencies?: Array<StepDependency>;
  conditionals?: Array<Conditional>;
  actions?: Array<StepAction>;
  redline_id?: string;
  created_during_run: true;
  created_at: string;
  created_by: string;
  orphaned?: boolean;
  moved?: boolean;
  source_run_id?: string;
} & Omit<StepSettings, 'runAsBatch'>;

export type RunAddedStep = {
  id: string;
  name: string;
  content: Array<RunStepBlock>;
  signoffs: Array<Signoff>;
  dependencies?: Array<StepDependency>;
  conditionals?: Array<Conditional>;
  actions?: Array<StepAction>;
  completed?: true;
  completedAt?: string;
  completedUserId?: string;
  skipped?: true;
  skippedAt?: string;
  skippedUserId?: string;
  snippet_id?: string;
  snippet_name?: string;
  snippet_rev?: number;
  snippet_ids_map?: Record<string, string>;
  timing?: string;
  timer?: StepTiming;
  duration?: StepDuration | string;
  location?: string;
  channel?: string;
  created_during_run: true;
  created_at: string;
  created_by: string;
  comments?: Array<RunStepComment>;
  /*
   * The state property, if it exists, on any type of run step can only be failed. Completed steps have a completed property, and skipped steps have a skipped property.
   * The state property is meant to be a move towards consolidating these various properties into one property.
   */
  state?: 'failed';
  /** @deprecated */
  requires_previous?: boolean;
  run_only?: boolean;
} & IRedlineId &
  Omit<StepSettings, 'runAsBatch'>;

export type AddedStep = DraftAddedStep | RunAddedStep;

/*
 * =============================================================================
 * Step header types
 * =============================================================================
 */
export type DraftStepHeader = {
  id: string;
  name: string;
  content: Array<RunStepHeaderBlock>;
};

export type ReleaseStepHeader = {
  id: string;
  name: string;
  content: Array<ReleaseStepHeaderBlock>;
};

export type RunStepHeader = {
  id: string;
  name: string;
  content: Array<RunStepHeaderBlock>;
};

export type StepHeader = DraftStepHeader | ReleaseStepHeader | RunStepHeader;

/*
 * =============================================================================
 * Util types
 * =============================================================================
 */
export type RepeatedSectionOrStep = RepeatedSection | RepeatedStep;

export type SnippetRef = {
  snippet_id?: string;
  snippet_name?: string;
  snippet_rev?: number;
  snippet_ids_map?: Record<string, string>;
  deleted?: boolean;
};

/*
 * =============================================================================
 * Comment types
 * =============================================================================
 */
export type ReleaseNote = {
  user_id: string;
  timestamp: string;
  text: string;
};

export type DraftRedlineComment = {
  id: string;
  reference_id: string;
  redline_id?: string;
  text: string;
  user_id: string;
  created_at: string;
  updated_at?: string;
  type?: 'redline_comment';
  source_run_id: string;
  resolved?: boolean;
  resolved_by?: string;
  resolved_at?: string;
  resolution_procedure_rev_num?: number;
};

export type ReviewComment = {
  id: string;
  parent_id?: string;
  reference_id: string;
  text: string;
  user_id: string;
  created_at: string;
  updated_at?: string;
  type?: 'review_comment';
  resolved?: boolean;
  resolved_by?: string;
  resolved_at?: string;
  resolution_procedure_rev_num?: number;
  mention_list?: Array<Mention>;
};

export type RunRedlineComment = {
  id: string;
  text: string;
  user_id: string;
  created_at: string;
  updated_at?: string;
  redline_id?: string;
  /** @deprecated */
  redlineId?: string;
};

/*
 * =============================================================================
 * Redline types
 * =============================================================================
 */
export type DraftHeaderFieldRedline = {
  redline_id?: string;
  created_at?: string;
  user_id?: string;
  redlineIndex: number;
  name: string;
  /** @deprecated */
  redlineId?: string;
  /** @deprecated */
  createdAt?: string;
  /** @deprecated */
  userId?: string;
};

export type BlockRedline<
  BlockT extends DraftStepRedlinedBlock | DraftHeaderRedlinedBlock
> = {
  block: BlockT;
};

export type DraftStepFieldRedline = {
  redline_id?: string;
  created_at?: string;
  user_id?: string;
  redlineIndex: number;
  name: string;
  /** @deprecated */
  redlineId?: string;
  /** @deprecated */
  createdAt?: string;
  /** @deprecated */
  userId?: string;
};

export type DraftBlockRedline = DraftHeaderBlockRedline | DraftStepBlockRedline;
export type DraftStepRedlinedBlock =
  | ReleaseAlertBlock
  | ReleaseFieldInputBlock
  | ReleaseTextBlock;
export type DraftStepBlockRedline = BlockRedline<DraftStepRedlinedBlock>;

export type DraftHeaderRedlinedBlock = ReleaseAlertBlock | ReleaseTextBlock;
export type DraftHeaderBlockRedline = BlockRedline<DraftHeaderRedlinedBlock>;

export type RunHeaderFieldRedline = {
  redline_id?: string;
  created_at?: string;
  user_id?: string;
  pending?: boolean;
  header: RedlinedHeader;
  field: 'name';
  run_only?: boolean;
  /** @deprecated */
  redlineId?: string;
  /** @deprecated */
  createdAt?: string;
  /** @deprecated */
  userId?: string;
};

export type RunHeaderBlockRedline = {
  redline_id?: string;
  created_at?: string;
  user_id?: string;
  content_id: string;
  pending?: boolean;
  header: RedlinedHeader;
  /** @deprecated */
  redlineId?: string;
  run_only?: boolean;
};

export type RunHeaderRedline = RunHeaderFieldRedline | RunHeaderBlockRedline;

export type RunStepFieldRedline = {
  redline_id?: string;
  created_at?: string;
  user_id?: string;
  acceptedBy?: string;
  acceptedAt?: string;
  pending?: boolean;
  step: RedlinedStep;
  field: 'name';
  /** @deprecated */
  redlineId?: string;
  /** @deprecated */
  createdAt?: string;
  /** @deprecated */
  userId?: string;
  run_only?: boolean;
};

export type RunStepBlockRedline = {
  redline_id?: string;
  created_at?: string;
  user_id?: string;
  acceptedBy?: string;
  acceptedAt?: string;
  pending?: boolean;
  step: RedlinedStep;
  source_content_id?: string;
  /** @deprecated */
  redlineId?: string;
  /** @deprecated */
  createdAt?: string;
  /** @deprecated */
  userId?: string;
};

export type RunStepRedline = RunStepFieldRedline | RunStepBlockRedline;

/*
 * =============================================================================
 * Block types
 * =============================================================================
 */
export type DraftHeaderBlock =
  | DraftAlertBlock
  | DraftAttachmentBlock
  | DraftTextBlock;

export type ReleaseHeaderBlock =
  | ReleaseAlertBlock
  | ReleaseAttachmentBlock
  | ReleaseTextBlock;

export type RunHeaderBlock = RunAlertBlock | RunAttachmentBlock | RunTextBlock;

export type HeaderBlock =
  | DraftHeaderBlock
  | ReleaseHeaderBlock
  | RunHeaderBlock;

export type DraftSectionHeaderBlock = ReleaseHeaderBlock;
export type ReleaseSectionHeaderBlock = ReleaseHeaderBlock;
export type RunSectionHeaderBlock = RunHeaderBlock;

// RunSectionHeaderBlock

// DraftStepHeaderBlock

export type ReleaseStepHeaderBlock = ReleaseAlertBlock | ReleaseTextBlock;

export type RunStepHeaderBlock = RunAlertBlock | RunTextBlock;

export type StepHeaderBlock = ReleaseStepHeaderBlock | RunStepHeaderBlock;

// TODO: Include Part Usage Draft/Release/Run Blocks
export type DraftStepBlock =
  | DraftAlertBlock
  | DraftAttachmentBlock
  | DraftCommandingBlock
  | DraftExpressionBlock
  | DraftExternalItemBlock
  | DraftFieldInputBlock
  | DraftInputReferenceBlock
  | DraftJumpToBlock
  | DraftProcedureLinkBlock
  | DraftRequirementBlock
  | DraftTableInputBlock
  | DraftTelemetryBlock
  | DraftTextBlock
  | DraftTestCasesBlock
  | DraftPartBuildBlock
  | DraftPartUsageBlock
  | DraftPartKitBlock
  | DraftInventoryDetailInputBlock
  | DraftFieldInputTableBlock;

export type ReleaseStepBlock =
  | ReleaseAlertBlock
  | ReleaseAttachmentBlock
  | ReleaseCommandingBlock
  | ReleaseExpressionBlock
  | ReleaseExternalItemBlock
  | ReleaseFieldInputBlock
  | ReleaseInputReferenceBlock
  | ReleaseJumpToBlock
  | ReleaseProcedureLinkBlock
  | ReleaseRequirementBlock
  | ReleaseTableInputBlock
  | ReleaseTelemetryBlock
  | ReleaseTextBlock
  | ReleaseTestCasesBlock
  | ReleasePartBuildBlock
  | ReleasePartUsageBlock
  | ReleasePartKitBlock
  | ReleaseInventoryDetailInputBlock
  | ReleaseFieldInputTableBlock;

export type RunStepBlock =
  | RunAlertBlock
  | RunAttachmentBlock
  | RunCommandingBlock
  | RunExpressionBlock
  | RunExternalItemBlock
  | RunFieldInputBlock
  | RunInputReferenceBlock
  | RunJumpToBlock
  | RunProcedureLinkBlock
  | RunRequirementBlock
  | RunTableInputBlock
  | RunTelemetryBlock
  | RunTextBlock
  | RunTestCasesBlock
  | RunPartBuildBlock
  | RunPartUsageBlock
  | RunPartKitBlock
  | RunInventoryDetailInputBlock
  | RunFieldInputTableBlock;

export type StepBlock = DraftStepBlock | ReleaseStepBlock | RunStepBlock;

export type ContentBlock = HeaderBlock | StepBlock;

export type RunStepBlockWithRecorded =
  | RunCommandingBlock
  | RunFieldInputBlock
  | RunTableInputBlock
  | RunPartKitBlock
  | RunInventoryDetailInputBlock;

export type AttachmentValue = {
  attachment_id: string;
  name: string;
  content_type: string;
};

export type SketchValue = {
  attachment_id?: string;
  name?: string;
  content_type?: string;
  text?: string;
};

export type ExternalDataValue = {
  id: string;
  name: string;
  label?: string;
  url?: string;
  valid?: boolean;
  metadata?: object;
  details?: Array<ExternalDataValueDetails>;
};

export type ExternalDataValueDetails = {
  name: string;
  value: string;
};

export type ExternalSearchDataValue = Overwrite<
  ExternalDataValue,
  { name?: string }
>;

export type TimestampValue = string | TimestampValueV2;

export type TimestampValueV2 = {
  date?: string | null;
  time?: string | null;
  zone?: string;
};

export type DraftBlockRedlines = {
  redlines?: Array<DraftBlockRedline>;
};

export type BlockType =
  | 'alert'
  | 'attachment'
  | 'commanding'
  | 'conditionals'
  | 'dependencies'
  | 'expression'
  | 'external_item'
  | 'input'
  | 'jump_to'
  | 'procedure_link'
  | 'reference'
  | 'requirement'
  | 'table_input'
  | 'telemetry'
  | 'text'
  | 'part_kit'
  | 'part_build'
  | 'inventory_detail_input'
  | 'tool_check_out'
  | 'tool_check_in'
  | 'part_list'
  | 'part_usage'
  | 'tool_usage'
  | 'test_cases'
  | 'field_input_table';

export type AlertSubtype = 'note' | 'caution' | 'warning';

export type DraftAlertBlock = {
  id: string;
  type: 'alert';
  subtype: AlertSubtype;
  text: string;
  redlines?: Array<BlockRedline<ReleaseAlertBlock>>;
};

export type ReleaseAlertBlock = {
  id: string;
  type: 'alert';
  subtype: AlertSubtype;
  text: string;
};

export type RunAlertBlock = {
  id: string;
  type: 'alert';
  subtype: AlertSubtype;
  text: string;
};

export type AlertBlock = DraftAlertBlock | ReleaseAlertBlock | RunAlertBlock;

export type DraftAttachmentBlock = {
  id: string;
  type: 'attachment';
  attachment_id?: string;
  name?: string;
  file?: File;
  caption?: string;
  size?: 'small' | 'best_fit' | 'original';
  content_type?: string;
  display_style?: 'inline' | 'to_the_side';
};

export type ReleaseAttachmentBlock = {
  id: string;
  type: 'attachment';
  name?: string;
  file?: File;
  caption?: string;
  attachment_id?: string;
  content_type?: string;
  size?: 'small' | 'best_fit' | 'original';
  display_style?: 'inline' | 'to_the_side';
};

export type RunAttachmentBlock = {
  id: string;
  type: 'attachment';
  name?: string;
  file?: File;
  caption?: string;
  attachment_id?: string;
  content_type?: string;
  size?: 'small' | 'best_fit' | 'original';
  display_style?: 'inline' | 'to_the_side';
};

export type AttachmentBlock =
  | DraftAttachmentBlock
  | ReleaseAttachmentBlock
  | RunAttachmentBlock;

export type DraftCommandingBlock = {
  id: string;
  type: 'commanding';
  key: 'command' | 'COSMOS';
  name?: string;
  // favoring the command id over legacy command name for unique identifier
  command_id?: number;
  dictionary_id?: number;
  arguments?: CommandingBlockArguments;
  include_in_summary?: boolean;
  /** @deprecated */
  cosmos: CosmosCommand;
};

export type ReleaseCommandingBlock = {
  id: string;
  type: 'commanding';
  name?: string;
  // favoring the command id over legacy command name for unique identifier
  command_id?: number;
  dictionary_id?: number;
  key: 'command' | 'COSMOS';
  arguments?: CommandingBlockArguments;
  include_in_summary?: boolean;
  /** @deprecated */
  cosmos: CosmosCommand;
};

type RunCommandingRecorded = Array<CommandingBlockRecorded>;

export type RunCommandingBlock = {
  id: string;
  type: 'commanding';
  name?: string;
  // favoring the command id over legacy command name for unique identifier
  command_id?: number;
  dictionary_id?: number;
  key: 'command' | 'COSMOS';
  recorded?: RunCommandingRecorded;
  arguments?: CommandingBlockArguments;
  include_in_summary?: boolean;
  /** @deprecated */
  cosmos: CosmosCommand;
};

export type CommandingBlockRecorded = {
  results?: CommandingBlockRecordedResults;
  timestamp?: string;
  pendingArguments?: CommandingBlockArguments;
};

export type CommandingBlockRecordedResults = {
  name: string;
  arguments?: Array<Argument> | object | null;
  success: boolean;
  message?: string;
  results: object | null;
  received_at: string;
};

export type Argument = {
  name: string;
  type: 'float' | 'int' | 'string' | 'enum' | 'file';
  values?: Array<object>;
};

export type CommandingBlockArguments = {
  [argument: string]: string | null;
} | null;

export type CommandingBlock =
  | DraftCommandingBlock
  | ReleaseCommandingBlock
  | RunCommandingBlock;

export type CommandingBlockDiffElement = WithDiffChange<
  | CommandingBlock
  | {
      id: string;
      type: 'commanding';
      name?: DiffField<string>;
      // favoring the command id over legacy command name for unique identifier
      command_id?: DiffField<number>;
      dictionary_id?: DiffField<number>;
      key: DiffField<'command' | 'COSMOS'>;
      arguments?: CommandingBlockArgumentsDiffElement;
      include_in_summary?: boolean;
      /** @deprecated */
      cosmos: CosmosCommand;
    }
>;

export type CommandingBlockArgumentsDiffElement = {
  [argument: string]: DiffField<string | null>;
} | null;

/** @deprecated */
export type CosmosCommand = {
  target: string;
  command: string;
};

export type ExpressionToken = {
  type: 'text' | 'reference';
  value: string;
  reference_id?: string;
  field_index?: number;
};

export type ExpressionBlock =
  | DraftExpressionBlock
  | ReleaseExpressionBlock
  | RunExpressionBlock;

export type ExpressionBlockDiffElement = WithDiffChange<ExpressionBlock>;
export type ExpressionBlockDiff = DiffArrayElement<ExpressionBlockDiffElement>;

export type ExpressionTokenDiffElement = WithDiffChange<ExpressionToken>;
export type ExpressionTokenDiff = DiffArrayElement<ExpressionTokenDiffElement>;
export type DraftExpressionBlock = {
  id: string;
  type: 'expression';
  name: string;
  tokens: ExpressionToken[];
  include_in_summary?: boolean;
};

export type ReleaseExpressionBlock = {
  id: string;
  type: 'expression';
  name: string;
  tokens: ExpressionToken[];
  include_in_summary?: boolean;
};

export type ExpressionReferences = {
  [content_id: string]: {
    referenced_section_id: string;
    referenced_step_id: string;
  };
};
export type RunExpressionBlock = {
  id: string;
  type: 'expression';
  name: string;
  tokens: ExpressionToken[];
  include_in_summary?: boolean;
  recorded?: {
    value?: string;
    display?: string;
    references?: ExpressionReferences;
  };
};

export type DraftExternalItemBlock = {
  dictionary_id?: number;
  id: string;
  type: 'external_item';
  item_type: string;
  item_id: string;
  item_url?: string;
  item_valid?: boolean;
  item?: {
    id: string;
    name?: string;
    label?: string;
    url?: string;
    valid?: boolean;
  };
};

export type ReleaseExternalItemBlock = {
  dictionary_id?: number;
  id: string;
  type: 'external_item';
  item_type: string;
  item_id: string;
  item?: {
    id: string;
    name?: string;
    label?: string;
    url?: string;
    valid?: boolean;
  };
};

export type RunExternalItemBlock = {
  dictionary_id?: number;
  id: string;
  type: 'external_item';
  item_type: string;
  item_id: string;
  item?: {
    id: string;
    type?: string;
    name?: string;
    label?: string;
    url?: string;
    valid?: boolean;
    metadata?: unknown;
  };
};

export type ExternalItemBlock =
  | DraftExternalItemBlock
  | ReleaseExternalItemBlock
  | RunExternalItemBlock;

export type ExternalItemBlockDiffElement = WithDiffChange<
  | ExternalItemBlock
  | {
      dictionary_id?: DiffField<number>;
      id: string;
      type: 'external_item';
      item_type: DiffField<string>;
      item_id: DiffField<string>;
      item_url?: DiffField<string>;
      item_valid?: DiffField<boolean>;
      item?: {
        id: string;
        name?: string;
        label?: string;
        url?: string;
        valid?: boolean;
      };
    }
>;

export type FieldInputType =
  | 'text'
  | 'number'
  | 'checkbox'
  | 'timestamp'
  | 'attachment'
  | 'sketch'
  | 'list'
  | 'select'
  | 'external_item'
  | 'external_search'
  | 'multiple_choice';

export type Rule = {
  op: RuleOperator;
  value: string | number; // value is sometimes represented as a number, and sometimes as a string in the docs
  range?: Range;
};

export type RuleDiffElement = WithDiffChange<
  | Rule
  | {
      op: DiffField<string>;
      value: DiffField<string | number>;
      range?: RangeDiffElement;
    }
>;

// Range min and max are sometimes represented as numbers, and sometimes as strings in the docs
export type Range = {
  min: string | number;
  max: string | number;
  include_min?: boolean;
  include_max?: boolean;
};

export type RangeDiffElement = WithDiffChange<
  | Range
  | {
      min: DiffField<string | number>;
      max: DiffField<string | number>;
      include_min?: DiffField<boolean>;
      include_max?: DiffField<boolean>;
    }
>;

export type RunFieldInputRecordedValue =
  | null
  | string
  | number
  | boolean
  | AttachmentValue
  | SketchValue
  | ExternalDataValue
  | ExternalSearchDataValue
  | TimestampValue;

export type RunFieldInputRecorded<T = RunFieldInputRecordedValue> = {
  value: T;
};

export type DraftFieldInputBlock =
  | DraftFieldInputTextBlock
  | DraftFieldInputNumberBlock
  | DraftFieldInputCheckboxBlock
  | DraftFieldInputTimestampBlock
  | DraftFieldInputAttachmentBlock
  | DraftFieldInputSketchBlock
  | DraftFieldInputSettingsListBlock
  | DraftFieldInputCustomListBlock
  | DraftFieldInputExternalDataBlock
  | DraftFieldInputExternalSearchBlock
  | DraftFieldInputMultipleChoiceBlock;

export type ReleaseFieldInputBlock =
  | ReleaseFieldInputTextBlock
  | ReleaseFieldInputNumberBlock
  | ReleaseFieldInputCheckboxBlock
  | ReleaseFieldInputTimestampBlock
  | ReleaseFieldInputAttachmentBlock
  | ReleaseFieldInputSketchBlock
  | ReleaseFieldInputSettingsListBlock
  | ReleaseFieldInputCustomListBlock
  | ReleaseFieldInputExternalDataBlock
  | ReleaseFieldInputExternalSearchBlock
  | ReleaseFieldInputMultipleChoiceBlock;

export type RunFieldInputBlock =
  | RunFieldInputTextBlock
  | RunFieldInputNumberBlock
  | RunFieldInputCheckboxBlock
  | RunFieldInputTimestampBlock
  | RunFieldInputAttachmentBlock
  | RunFieldInputSketchBlock
  | RunFieldInputSettingsListBlock
  | RunFieldInputCustomListBlock
  | RunFieldInputExternalDataBlock
  | RunFieldInputExternalSearchBlock
  | RunFieldInputMultipleChoiceBlock;

export type FieldInputBlock =
  | DraftFieldInputBlock
  | ReleaseFieldInputBlock
  | RunFieldInputBlock;

export type DraftInputReferenceBlock = {
  id: string;
  type: 'reference';
  subtype: 'input';
  reference: string;
  sub_reference?: string;
  include_in_summary?: boolean;
};

export type ReleaseInputReferenceBlock = {
  id: string;
  type: 'reference';
  subtype: 'input';
  reference: string;
  sub_reference?: string;
  include_in_summary?: boolean;
};

export type RunInputReferenceBlock = {
  id: string;
  type: 'reference';
  subtype: 'input';
  reference: string;
  sub_reference?: string;
  include_in_summary?: boolean;
};

export type InputReferenceBlock =
  | DraftInputReferenceBlock
  | ReleaseInputReferenceBlock
  | RunInputReferenceBlock;

export type InputReferenceBlockDiff = WithDiffChange<
  | InputReferenceBlock
  | {
      id: string;
      type: 'reference';
      subtype: 'input';
      reference: DiffField<string>;
      sub_reference?: DiffField<string>;
      include_in_summary?: boolean;
    }
>;

export type DraftJumpToBlock = {
  id: string;
  type: 'jump_to';
  jumpToId: string;
};

export type ReleaseJumpToBlock = {
  id: string;
  type: 'jump_to';
  jumpToId: string;
};

export type RunJumpToBlock = {
  id: string;
  type: 'jump_to';
  jumpToId: string;
};

export type JumpToBlock =
  | DraftJumpToBlock
  | ReleaseJumpToBlock
  | RunJumpToBlock;

export type DraftProcedureLinkBlock = {
  id: string;
  type: 'procedure_link';
  procedure: string;
  section: string;
  run?: string;
};

export type ReleaseProcedureLinkBlock = {
  id: string;
  type: 'procedure_link';
  procedure: string;
  section: string;
  run?: string;
};

export type RunProcedureLinkBlock = {
  id: string;
  type: 'procedure_link';
  procedure: string;
  section: string;
  run?: string;
  run_only?: boolean;
};

export type ProcedureLinkBlock =
  | DraftProcedureLinkBlock
  | ReleaseProcedureLinkBlock
  | RunProcedureLinkBlock;

export type ProcedureLinkBlockDiffElement = WithDiffChange<
  | ProcedureLinkBlock
  | {
      id: string;
      type: 'procedure_link';
      procedure: DiffField<string>;
      section: DiffField<string>;
      run?: string;
    }
>;

export type RequirementSubType = 'manual' | 'jama' | 'epsilon3';

export type DraftRequirementBlock = {
  id: string;
  type: 'requirement';
  requirement_id: string;
  label: string;
  subType: RequirementSubType;
  integrationDetails?: RequirementIntegrationDetails;
  metadata: object;
};

export type ReleaseRequirementBlock = {
  id: string;
  type: 'requirement';
  requirement_id: string;
  label: string;
  subType: RequirementSubType;
  integrationDetails?: RequirementIntegrationDetails;
  metadata: object;
};

export type RunRequirementBlock = {
  id: string;
  type: 'requirement';
  requirement_id: string;
  label: string;
  subType: RequirementSubType;
  integrationDetails?: RequirementIntegrationDetails;
  metadata: object;
};

export type RequirementBlock =
  | DraftRequirementBlock
  | ReleaseRequirementBlock
  | RunRequirementBlock;

export type TableSignoffAction = {
  timestamp: string;
  user_id: string;
  type: 'signoff';
  operator?: string;
};

export type TableRevokeSignoffAction = {
  timestamp: string;
  user_id: string;
  type: 'revoke_signoff';
  revoked_user_id: string;
  revoked_operator?: string;
};

export type TableAction = TableSignoffAction | TableRevokeSignoffAction;

export type TableSignoff = {
  id: string;
  operators: Array<string>;
  actions: Array<TableAction>;
};

export type TableCell =
  | string
  | boolean
  | Array<TableSignoff>
  | Array<RunStepComment>
  | Case // TODO: Make sure this type is correct
  | null;
export type TableCells = Array<Array<TableCell>>;

export type TableColumn = {
  id?: string;
  name: string;
  column_type?: 'text' | 'input' | 'signoff' | 'comment' | 'test_point';
  input_type?: 'text' | 'number' | 'checkbox' | 'list';
  units: string;
  width?: number;
  list?: string;
  allow_input_after_signoff?: boolean;
  disabled?: boolean;
};

export type DraftTableInputBlock = {
  id: string;
  type: 'table_input';
  sub_type?: '' | 'test_point';
  columns: Array<TableColumn>;
  rows: number;
  cells?: TableCells;
  include_in_summary?: boolean;
};

export type ReleaseTableInputBlock = {
  id: string;
  type: 'table_input';
  sub_type?: '' | 'test_point';
  columns: Array<TableColumn>;
  rows: number;
  cells?: TableCells;
  include_in_summary?: boolean;
};

export type RunTableInputRecorded = {
  values: TableCells;
  version: number;
};

export type RunTableInputBlock = {
  id: string;
  type: 'table_input';
  sub_type?: '' | 'test_point';

  columns: Array<TableColumn>;
  rows: number;
  cells?: TableCells;
  include_in_summary?: boolean;
  recorded?: RunTableInputRecorded;
};

export type TableInputBlock =
  | DraftTableInputBlock
  | ReleaseTableInputBlock
  | RunTableInputBlock;

export type TableColumnDiffElement = WithDiffChange<
  | TableColumn
  | {
      id?: string;
      name: DiffField<string>;
      column_type?: DiffField<'text' | 'input'>;
      input_type?: DiffField<'text' | 'number' | 'checkbox'>;
      units: DiffField<string>;
      width?: DiffField<number>;
    }
>;

export type TableInputBlockDiffElement = WithDiffChange<
  | TableInputBlock
  | {
      id: string;
      type: 'table_input';
      columns: Array<TableColumnDiffElement>;
      rows: DiffField<number>;
      cells?: Array<Array<DiffField<string | null>>>;
      // The added/deleted fields for rows and columns are used for diffs only.
      removed_rows?: Set<number>;
      added_rows?: Set<number>;
      removed_columns?: Set<number>;
      added_columns?: Set<number>;
    }
>;

export type TableSignoffDiff = DiffArrayElement<TableSignoff>;
export type TableSignoffDiffElement = WithDiffChange<TableSignoff>;

/** @deprecated */
export type CosmosTelemetry = {
  target: string;
  packet: string;
  item: string;
};

export type RuleOperator = SupportedOperation | 'range' | '';
export type DraftTelemetryBlock = {
  id: string;
  type: 'telemetry';
  key: 'parameter' | 'custom';
  name?: string;
  units?: string;
  // favoring the parameter id over legacy parameter name for unique identifier
  parameter_id?: number;
  dictionary_id?: number;
  parameter_type?: TelemetryType;
  rule: RuleOperator;
  value: string;
  expression?: string;
  cosmos?: CosmosTelemetry;
  range?: Range;
};

export type TelemetryBlockDiffElement = WithDiffChange<DraftTelemetryBlock>;

export type ReleaseTelemetryBlock = {
  id: string;
  type: 'telemetry';
  key: 'parameter' | 'custom';
  name?: string;
  units?: string;
  // favoring the parameter id over legacy parameter name for unique identifier
  parameter_id?: number;
  dictionary_id?: number;
  parameter_type?: TelemetryType;
  rule: RuleOperator;
  value: string;
  expression?: string;
  cosmos?: CosmosTelemetry;
  range?: Range;
};

type RunTelemetryRecorded =
  | {
      pass: boolean;
      value: number | string;
      timestamp?: string | null;
      stale?: boolean;
    }
  | Record<string, never>; // empty object

export type RunTelemetryBlock = {
  id: string;
  type: 'telemetry';
  key: 'parameter' | 'custom';
  name?: string;
  units?: string;
  // favoring the parameter id over legacy parameter name for unique identifier
  parameter_id?: number;
  dictionary_id?: number;
  parameter_type?: TelemetryType;
  refresh_rate_ms?: number;
  rule: RuleOperator;
  value: string;
  expression?: string;
  cosmos?: CosmosTelemetry;
  range?: Range;
  include_in_summary?: boolean;
  recorded?: RunTelemetryRecorded;
};

export type TelemetryBlock =
  | ReleaseTelemetryBlock
  | DraftTelemetryBlock
  | RunTelemetryBlock;

export type DraftTextBlock = {
  id: string;
  type: 'text';
  text: string;
  redlines?: Array<BlockRedline<ReleaseTextBlock>>;
  tokens?: ExpressionToken[];
};

export type DraftTestCasesBlock = {
  id: string;
  type: 'test_cases';
  items: Array<unknown>; //unknown until TestCase is moved to shared
};

export type ReleaseTestCasesBlock = {
  id: string;
  type: 'test_cases';
  items: Array<unknown>; //unknown until TestCase is moved to shared
};

export type RunTestCasesBlock = {
  id: string;
  type: 'test_cases';
  items: Array<unknown>; //unknown until TestCase is moved to shared
};

// TODO these part build and usage types are incomplete
export type DraftPartBuildBlock = {
  id: string;
  part_id: string;
  type: 'part_build';
  part?: Part;
  items: Array<{
    id: string;
    part_id: string;
    part_no: string;
    name: string;
    amount: number;
  }>;
};

export type ReleasePartBuildBlock = {
  id: string;
  part_id: string;
  type: 'part_build';
  part?: Part;
  items: Array<{
    id: string;
    part_id: string;
    part_no: string;
    name: string;
    amount: number;
  }>;
};

export type RunPartBuildBlock = {
  id: string;
  part_id: string;
  type: 'part_build';
  part?: Part;
  items: Array<{
    id: string;
    part_id: string;
    part_no: string;
    name: string;
    amount: number;
    serial?: string;
    part_index?: number;
  }>;
  recorded?: RunPartBuildRecorded;
};

export type RunPartBuildRecorded = {
  items: {
    [item_id: string]: PartBuildRecordedItem;
  };
  added_items?: PartBuildRecordedItem[];
};

export type PartBuildRecordedItem = {
  id: string;
  part_id: string;
  part_no: string;
  tracking?: TrackingType;
  revision?: string;
  revision_id?: string;
  name?: string;
  part_index?: number;
  amount: number;
  location_id?: string;
  lot?: string;
  prefix?: string;
  serial?: string;
  diff_change_state?: string;
  item_id?: string;
};

export type DraftPartUsageBlock = {
  id: string;
  part_id: string;
  type: 'part_usage';
};

export type ReleasePartUsageBlock = {
  id: string;
  part_id: string;
  type: 'part_usage';
};

export type RunPartUsageBlock = {
  id: string;
  part_id: string;
  type: 'part_usage';
  recorded?: Array<{
    part?: Part;
    item?: {
      part: Part;
    };
  }>;
};

export type DraftPartKitBlock = {
  id: string;
  part: Part | null;
  type: 'part_kit';
  items: Array<KitPart>;
};

export type ReleasePartKitBlock = {
  id: string;
  part: Part | null;
  type: 'part_kit';
  items: Array<KitPart>;
};

export type RunPartKitBlock = {
  id: string;
  part: Part | null;
  type: 'part_kit';
  items: Array<KitPart>;
  recorded?: RunPartKitRecorded;
  include_in_summary?: boolean;
};

// TODO: Add Part Usage Blocks
export type RunPartKitRecorded = {
  items?: Record<string, KitItem>;
  added_items?: Array<KitItem>;
};

export type KitPart = {
  id: string;
  part_id: string;
  amount: number;
  part_no?: string;
  name?: string;
  revision?: string;
  revision_id?: string;
  tracking?: TrackingType;
};

export type KitItem = KitPart & { item_id?: string };

export type DraftToolCheckOutBlock = {
  id: string;
  type: 'tool_check_out';
  tool_id: number | null;
  include_in_summary?: boolean;
};

export type ReleaseToolCheckOutBlock = {
  id: string;
  type: 'tool_check_out';
  tool_id: number;
  include_in_summary?: boolean;
};

type RunToolCheckoutRecorded = ReleaseToolCheckOutBlock & {
  tool_instance_id: number;
};

export type RunToolCheckOutBlock = ReleaseToolCheckOutBlock & {
  recorded?: RunToolCheckoutRecorded;
};

export type ToolCheckOutBlock =
  | DraftToolCheckOutBlock
  | ReleaseToolCheckOutBlock
  | RunToolCheckOutBlock;

export type ReviewToolCheckOutBlock = WithDiffChange<
  | DraftToolCheckOutBlock
  | {
      id: string;
      type: 'tool_check_out';
      tool_id: DiffField<number>;
    }
>;

export type DraftToolCheckInBlock = {
  id: string;
  type: 'tool_check_in';
  tool_id: number | null;
};

export type ReleaseToolCheckInBlock = {
  id: string;
  type: 'tool_check_in';
  tool_id: number;
};

type RunToolCheckInRecorded = ReleaseToolCheckInBlock & {
  tool_instance_id: number;
};

export type RunToolCheckInBlock = ReleaseToolCheckInBlock & {
  recorded?: RunToolCheckInRecorded;
};

export type ToolCheckInBlock =
  | DraftToolCheckInBlock
  | ReleaseToolCheckInBlock
  | RunToolCheckInBlock;

export type ReviewToolCheckInBlock = WithDiffChange<
  | DraftToolCheckInBlock
  | {
      id: string;
      type: 'tool_check_in';
      tool_id: DiffField<number>;
      tool_instance_id: DiffField<number>;
    }
>;

export type DraftToolUsageBlock = {
  id: string;
  type: 'tool_usage';
  tool_id: number | null;
  usage_type_id: number | null;
  include_in_summary?: boolean;
};

export type ReleaseToolUsageBlock = {
  id: string;
  type: 'tool_usage';
  tool_id: number;
  usage_type_id: number;
  include_in_summary?: boolean;
};

type RunToolUsageRecorded = ReleaseToolUsageBlock & {
  tool_instance_id?: number;
  value?: number;
};
export type RunToolUsageBlock = ReleaseToolUsageBlock & {
  recorded?: RunToolUsageRecorded;
};

export type ToolUsageBlock =
  | DraftToolUsageBlock
  | ReleaseToolUsageBlock
  | RunToolUsageBlock;

export type ReviewToolUsageBlock = WithDiffChange<
  | DraftToolUsageBlock
  | {
      id: string;
      type: 'tool_usage';
      tool_id: DiffField<number>;
      tool_instance_id: DiffField<number>;
    }
>;

export type ReleaseTextBlock = {
  id: string;
  type: 'text';
  text: string;
  tokens?: ExpressionToken[];
};

export type RunTextBlock = {
  id: string;
  type: 'text';
  text: string;
  tokens?: ExpressionToken[];
  recorded?: {
    value?: string;
  };
};

export type TextBlock = DraftTextBlock | ReleaseTextBlock | RunTextBlock;

/*
 * =============================================================================
 * Field input types
 * =============================================================================
 */
export type DraftFieldInputTextBlock = {
  id: string;
  type: 'input';
  inputType: 'text';
  name: string;
  units?: string;
  redlines?: Array<BlockRedline<ReleaseFieldInputTextBlock>>;
};

export type ReleaseFieldInputTextBlock = {
  id: string;
  type: 'input';
  inputType: 'text';
  name: string;
  units?: string;
};

export type RunFieldInputTextBlock = {
  id: string;
  type: 'input';
  inputType: 'text';
  name: string;
  units?: string;
  recorded?: { value: string };
};

export type FieldInputTextBlock =
  | DraftFieldInputTextBlock
  | ReleaseFieldInputTextBlock
  | RunFieldInputTextBlock;

export type FieldInputTextBlockDiffElement = WithDiffChange<
  | FieldInputTextBlock
  | {
      id: string;
      type: 'input';
      inputType: 'text';
      name: DiffField<string>;
      units?: DiffField<string>;
    }
>;

export type DraftFieldInputNumberBlock = {
  id: string;
  type: 'input';
  inputType: 'number';
  name: string;
  units: string;
  rule?: Rule;
  redlines?: Array<BlockRedline<ReleaseFieldInputNumberBlock>>;
  include_in_summary?: boolean;
};

export type ReleaseFieldInputNumberBlock = {
  id: string;
  type: 'input';
  inputType: 'number';
  name: string;
  units: string;
  rule?: Rule;
};

export type RunFieldInputNumberBlock = {
  id: string;
  type: 'input';
  inputType: 'number';
  name: string;
  units: string;
  rule?: Rule;
  recorded?: { value: number };
};

export type FieldInputNumberBlock =
  | DraftFieldInputNumberBlock
  | ReleaseFieldInputNumberBlock
  | RunFieldInputNumberBlock;

export type FieldInputNumberBlockDiffElement = WithDiffChange<
  | FieldInputNumberBlock
  | {
      id: string;
      type: 'input';
      inputType: 'number';
      name: DiffField<string>;
      units: DiffField<string>;
      rule?: RuleDiffElement;
    }
>;

export type DraftInventoryDetailInputBlock = {
  id: string;
  type: 'inventory_detail_input';
  part_revision_id: string;
  detail_id: string;
  include_in_summary?: boolean;
  part_id?: string;
};

export type ReleaseInventoryDetailInputBlock = DraftInventoryDetailInputBlock;

export type RunInventoryDetailInputRecorded = {
  value: string;
  item_id: string;
};

export type RunInventoryDetailInputBlock = ReleaseInventoryDetailInputBlock & {
  recorded?: RunInventoryDetailInputRecorded;
};

export type InventoryDetailInputBlock =
  | DraftInventoryDetailInputBlock
  | ReleaseInventoryDetailInputBlock
  | RunInventoryDetailInputBlock;

export type InventoryDetailInputBlockDiffElement = WithDiffChange<
  | InventoryDetailInputBlock
  | {
      id: string;
      type: 'inventory_detail_input';
      part_revision_id: DiffField<string>;
      detail_id: DiffField<string>;
      part_id?: string;
    }
>;

export type DraftFieldInputCheckboxBlock = {
  id: string;
  type: 'input';
  inputType: 'checkbox';
  name: string;
  redlines?: Array<BlockRedline<ReleaseFieldInputCheckboxBlock>>;
};

export type ReleaseFieldInputCheckboxBlock = {
  id: string;
  type: 'input';
  inputType: 'checkbox';
  name: string;
};

export type RunFieldInputCheckboxBlock = {
  id: string;
  type: 'input';
  inputType: 'checkbox';
  name: string;
  recorded?: { value: boolean };
};

export type FieldInputCheckboxBlock =
  | DraftFieldInputCheckboxBlock
  | ReleaseFieldInputCheckboxBlock
  | RunFieldInputCheckboxBlock;

export type FieldInputCheckboxBlockDiffElement = WithDiffChange<
  | FieldInputCheckboxBlock
  | {
      id: string;
      type: 'input';
      inputType: 'checkbox';
      name: DiffField<string>;
    }
>;

export type DraftFieldInputTimestampBlock = {
  id: string;
  type: 'input';
  inputType: 'timestamp';
  name: string;
  dateTimeType: 'date' | 'time' | 'datetime';
  redlines?: Array<BlockRedline<ReleaseFieldInputTimestampBlock>>;
};

export type ReleaseFieldInputTimestampBlock = {
  id: string;
  type: 'input';
  inputType: 'timestamp';
  name: string;
  dateTimeType: 'date' | 'time' | 'datetime';
};

export type RunFieldInputTimestampBlock = {
  id: string;
  type: 'input';
  inputType: 'timestamp';
  name: string;
  dateTimeType: 'date' | 'time' | 'datetime';
  recorded?: RunFieldInputRecorded<TimestampValue>;
};

export type FieldInputTimestampBlock =
  | DraftFieldInputTimestampBlock
  | ReleaseFieldInputTimestampBlock
  | RunFieldInputTimestampBlock;

export type FieldInputTimestampBlockDiffElement = WithDiffChange<
  | FieldInputTimestampBlock
  | {
      id: string;
      type: 'input';
      inputType: 'timestamp';
      name: DiffField<string>;
      dateTimeType: DiffField<'date' | 'time' | 'datetime'>;
    }
>;

export type DraftFieldInputAttachmentBlock = {
  id: string;
  type: 'input';
  inputType: 'attachment';
  name: string;
  redlines?: Array<BlockRedline<ReleaseFieldInputAttachmentBlock>>;
};

export type ReleaseFieldInputAttachmentBlock = {
  id: string;
  type: 'input';
  inputType: 'attachment';
  name: string;
};

export type RunFieldInputAttachmentBlock = {
  id: string;
  type: 'input';
  inputType: 'attachment';
  name: string;
  recorded?: { value: AttachmentValue };
};

export type FieldInputAttachmentBlock =
  | DraftFieldInputAttachmentBlock
  | ReleaseFieldInputAttachmentBlock
  | RunFieldInputAttachmentBlock;

export type FieldInputAttachmentBlockDiffElement = WithDiffChange<
  | FieldInputAttachmentBlock
  | {
      id: string;
      type: 'input';
      inputType: 'attachment';
      name: DiffField<string>;
    }
>;

export type DraftFieldInputSketchBlock = {
  id: string;
  type: 'input';
  inputType: 'sketch';
  name: string;
  redlines?: Array<BlockRedline<ReleaseFieldInputSketchBlock>>;
};

export type ReleaseFieldInputSketchBlock = {
  id: string;
  type: 'input';
  inputType: 'sketch';
  name: string;
};

export type RunFieldInputSketchBlock = {
  id: string;
  type: 'input';
  inputType: 'sketch';
  name: string;
  recorded?: { value: SketchValue };
};

export type FieldInputSketchBlock =
  | DraftFieldInputSketchBlock
  | ReleaseFieldInputSketchBlock
  | RunFieldInputSketchBlock;

export type FieldInputSketchBlockDiffElement = WithDiffChange<
  | FieldInputSketchBlock
  | {
      id: string;
      type: 'input';
      inputType: 'sketch';
      name: DiffField<string>;
    }
>;

export type DraftFieldInputSettingsListBlock = {
  id: string;
  type: 'input';
  inputType: 'list';
  name: string;
  list: string;
  redlines?: Array<BlockRedline<ReleaseFieldInputSettingsListBlock>>;
};

export type ReleaseFieldInputSettingsListBlock = {
  id: string;
  type: 'input';
  inputType: 'list';
  name: string;
  list: string;
};

export type RunFieldInputSettingsListBlock = {
  id: string;
  type: 'input';
  inputType: 'list';
  name: string;
  list: string;
  recorded?: { value: string };
};

export type FieldInputSettingsListBlock =
  | DraftFieldInputSettingsListBlock
  | ReleaseFieldInputSettingsListBlock
  | RunFieldInputSettingsListBlock;

export type FieldInputSettingsListBlockDiffElement = WithDiffChange<
  | FieldInputSettingsListBlock
  | {
      id: string;
      type: 'input';
      inputType: 'list';
      name: DiffField<string>;
      list: DiffField<string>;
    }
>;

export type DraftFieldInputCustomListBlock = {
  id: string;
  type: 'input';
  inputType: 'select';
  name: string;
  options: Array<string>;
  redlines?: Array<BlockRedline<ReleaseFieldInputCustomListBlock>>;
};

export type ReleaseFieldInputCustomListBlock = {
  id: string;
  type: 'input';
  inputType: 'select';
  name: string;
  options: Array<string>;
};

export type RunFieldInputCustomListBlock = {
  id: string;
  type: 'input';
  inputType: 'select';
  name: string;
  options: Array<string>;
  recorded?: { value: string };
};

export type FieldInputCustomListBlock =
  | DraftFieldInputCustomListBlock
  | ReleaseFieldInputCustomListBlock
  | RunFieldInputCustomListBlock;

export type FieldInputCustomListBlockDiffElement = WithDiffChange<
  | FieldInputCustomListBlock
  | {
      id: string;
      type: 'input';
      inputType: 'select';
      name: DiffField<string>;
      options: Array<DiffField<string>>;
    }
>;

export type DraftFieldInputExternalDataBlock = {
  id: string;
  type: 'input';
  inputType: 'external_item';
  name: string;
  external_item_type: string;
  dictionary_id?: number;
  redlines?: Array<BlockRedline<ReleaseFieldInputExternalDataBlock>>;
};

export type ReleaseFieldInputExternalDataBlock = {
  id: string;
  type: 'input';
  dictionary_id?: number;
  inputType: 'external_item';
  name: string;
  external_item_type: string;
};

export type RunFieldInputExternalDataBlock = {
  id: string;
  type: 'input';
  inputType: 'external_item';
  dictionary_id?: number;
  name: string;
  external_item_type: string;
  recorded?: { value: ExternalDataValue };
};

export type FieldInputExternalDataBlock =
  | DraftFieldInputExternalDataBlock
  | ReleaseFieldInputExternalDataBlock
  | RunFieldInputExternalDataBlock;

export type FieldInputExternalDataBlockDiffElement = WithDiffChange<
  | FieldInputExternalDataBlock
  | {
      id: string;
      type: 'input';
      dictionary_id?: DiffField<number>;
      inputType: 'external_item';
      name: DiffField<string>;
      external_item_type: DiffField<string>;
    }
>;

export type DraftFieldInputExternalSearchBlock = {
  id: string;
  name: string;
  type: 'input';
  inputType: 'external_search';
  external_search_type: {
    data_type_dictionary_id?: number;
    data_type: string;
    filter_options: Array<string>;
  };
  redlines?: Array<BlockRedline<ReleaseFieldInputExternalSearchBlock>>;
};

export type ReleaseFieldInputExternalSearchBlock = {
  id: string;
  name: string;
  type: 'input';
  inputType: 'external_search';
  external_search_type: {
    data_type_dictionary_id?: number;
    data_type: string;
    filter_options: Array<string>;
  };
};

export type RunFieldInputExternalSearchBlock = {
  id: string;
  name: string;
  type: 'input';
  inputType: 'external_search';
  external_search_type: {
    data_type_dictionary_id?: number;
    data_type: string;
    filter_options: Array<string>;
  };
  recorded?: { value: ExternalSearchDataValue | null };
};

export type FieldInputExternalSearchBlock =
  | DraftFieldInputExternalSearchBlock
  | ReleaseFieldInputExternalSearchBlock
  | RunFieldInputExternalSearchBlock;

export type FieldInputExternalSearchBlockDiffElement = WithDiffChange<
  | FieldInputExternalSearchBlock
  | {
      id: string;
      name: DiffField<string>;
      type: 'input';
      inputType: 'external_search';
      external_search_type: {
        data_type_dictionary_id?: DiffField<number>;
        data_type: DiffField<string>;
        filter_options: Array<DiffField<string>>;
      };
    }
>;

export type DraftFieldInputMultipleChoiceBlock = {
  id: string;
  type: 'input';
  inputType: 'multiple_choice';
  name: string;
  options: Array<string>;
  redlines?: Array<BlockRedline<ReleaseFieldInputMultipleChoiceBlock>>;
};

export type ReleaseFieldInputMultipleChoiceBlock = {
  id: string;
  type: 'input';
  inputType: 'multiple_choice';
  name: string;
  options: Array<string>;
};

export type RunFieldInputMultipleChoiceBlock = {
  id: string;
  type: 'input';
  inputType: 'multiple_choice';
  name: string;
  options: Array<string>;
  recorded?: { value: string };
};

export type FieldInputMultipleChoiceBlock =
  | DraftFieldInputMultipleChoiceBlock
  | ReleaseFieldInputMultipleChoiceBlock
  | RunFieldInputMultipleChoiceBlock;

export type FieldInputMultipleChoiceBlockDiffElement = WithDiffChange<
  | FieldInputMultipleChoiceBlock
  | {
      id: string;
      type: 'input';
      inputType: 'multiple_choice';
      name: DiffField<string>;
      options: Array<DiffField<string>>;
    }
>;

export type DraftFieldInputConditionalBlock =
  | DraftFieldInputSettingsListBlock
  | DraftFieldInputCustomListBlock
  | DraftFieldInputMultipleChoiceBlock;
export type ReleaseFieldInputConditionalBlock =
  | ReleaseFieldInputSettingsListBlock
  | ReleaseFieldInputCustomListBlock
  | ReleaseFieldInputMultipleChoiceBlock;
export type RunFieldInputConditionalBlock =
  | RunFieldInputSettingsListBlock
  | RunFieldInputCustomListBlock
  | RunFieldInputMultipleChoiceBlock;
export type FieldInputConditionalBlock =
  | DraftFieldInputConditionalBlock
  | ReleaseFieldInputConditionalBlock
  | RunFieldInputConditionalBlock;

export type ReleaseFieldInputTableBlock = {
  id: string;
  type: 'field_input_table';
  fieldsPerRow: number;
  include_in_summary?: boolean;
  fields: Array<
    | ReleaseFieldInputTextBlock
    | ReleaseFieldInputNumberBlock
    | ReleaseFieldInputCheckboxBlock
    | ReleaseFieldInputTimestampBlock
    | ReleaseFieldInputCustomListBlock
    | ReleaseFieldInputSettingsListBlock
  >;
};

export type RunFieldInputTableBlock = {
  id: string;
  type: 'field_input_table';
  fieldsPerRow: number;
  include_in_summary?: boolean;
  fields: Array<
    | RunFieldInputTextBlock
    | RunFieldInputNumberBlock
    | RunFieldInputCheckboxBlock
    | RunFieldInputTimestampBlock
    | RunFieldInputCustomListBlock
    | RunFieldInputSettingsListBlock
  >;
};

export type DraftFieldInputTableBlock = {
  id: string;
  type: 'field_input_table';
  fieldsPerRow: number;
  include_in_summary?: boolean;
  fields: Array<
    | DraftFieldInputTextBlock
    | DraftFieldInputNumberBlock
    | DraftFieldInputCheckboxBlock
    | DraftFieldInputTimestampBlock
    | DraftFieldInputCustomListBlock
    | DraftFieldInputSettingsListBlock
  >;
};

export type FieldInputBlockDiffElement =
  | FieldInputTextBlockDiffElement
  | FieldInputNumberBlockDiffElement
  | FieldInputCheckboxBlockDiffElement
  | FieldInputTimestampBlockDiffElement
  | FieldInputAttachmentBlockDiffElement
  | FieldInputSketchBlockDiffElement
  | FieldInputSettingsListBlockDiffElement
  | FieldInputCustomListBlockDiffElement
  | FieldInputExternalDataBlockDiffElement
  | FieldInputExternalSearchBlockDiffElement
  | FieldInputMultipleChoiceBlockDiffElement;

/*
 * =============================================================================
 * Additional view types
 * =============================================================================
 */
interface ProcedureLinkBase {
  code: string;
  name: string;
  procedure: string;
}

export type DraftProcedureLink = ProcedureLinkBase & {
  content: DraftProcedureLinkBlock;
};

export type ReleaseProcedureLink = ProcedureLinkBase & {
  content: ReleaseProcedureLinkBlock;
};

export type RunProcedureLink = ProcedureLinkBase & {
  content: RunProcedureLinkBlock;
};

export type ProcedureLink =
  | DraftProcedureLink
  | ReleaseProcedureLink
  | RunProcedureLink;

export type RunRedline =
  | RunHeaderRedline
  | RunStepRedline
  | RunRedlineComment
  | RunAddedStep;

// TODO: Include Part Checkin/Checkout/Usage recorded types
export type Recorded =
  | RunTelemetryRecorded
  | RunCommandingRecorded
  | RunToolUsageRecorded
  | RunToolCheckInRecorded
  | RunToolCheckoutRecorded
  | RunPartKitRecorded
  | RunTableInputRecorded
  | RunFieldInputRecorded
  | RunInventoryDetailInputRecorded;

export type TableCellRecorded = {
  row: number;
  column: number;
  value: TableCell;
  signoff_id?: string;
};

export type StepDocBlock<T extends RunStepBlock, R> = T & {
  actions?: Array<{
    user_id: string;
    action_id: string;
    timestamp: string;
    recorded: R;
  }>;
};

export type StepDocTableBlock = StepDocBlock<
  RunTableInputBlock,
  TableCellRecorded
>;

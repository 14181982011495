import React, { MouseEventHandler } from 'react';
import { useNavState } from '../../contexts/NavContext';

export interface ToolbarProps {
  children: React.ReactNode;
  bg?: string;
  includeVerticalGap?: boolean;
  /** @deprecated */
  onClick?: MouseEventHandler;
}

const Toolbar = ({ children, bg, includeVerticalGap = true, onClick }: ToolbarProps) => {
  const navState = useNavState();

  return (
    <>
      <div
        className={`fixed top-0 print:hidden ${
          navState.collapsed ? 'left-16' : 'left-64'
        } transition-all right-0 pl-2 pr-4 h-10 flex flex-row justify-between shadow-md ${bg ?? 'bg-gray-100'} z-50`}
        onClick={onClick}
        aria-label="Sticky Toolbar"
        role="toolbar"
      >
        {children}
      </div>
      {includeVerticalGap && <div className="h-8" />}
    </>
  );
};

export default React.memo(Toolbar);
